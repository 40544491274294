export const youtubeList = [
    {
        src: "https://www.youtube.com/embed/kyCVXdtrhfI",
        title: "Band Practice"
    },
    {
        src: "https://www.youtube.com/embed/GIz-77iRqp4",
        title: "An attempt at Syd's Western"
    },
    {
        src: "https://www.youtube.com/embed/9rhdceEHq34",
        title: "Kangaroo Song"
    },
    {
        src: "https://www.youtube.com/embed/GZtvGlD6BGg",
        title: "Burlington Bar show"
    },
    {
        src: "https://www.youtube.com/embed/dWbQw2ZU8hQ" ,
        title: "Moes Tavern Show"
    },
    {
        src: "https://www.youtube.com/embed/Uhs9Ozf_g-0" ,
        title: "Spotlight Live"
    },
    {
        src: "https://www.youtube.com/embed/goBnYrOkBLw" ,
        title: "Practicing a few things"
    },
    {
        src: "https://www.youtube.com/embed/sfPLQD9LtvY",
        title: "Spotlight Quip"
    },
    {
        src: "https://www.youtube.com/embed/84tZfBcPLPw",
        title: "Cancer Live"
    },
    {
        src: "https://www.youtube.com/embed/lb0nEV_92YA" ,
        title: "On the rise (pending title)"
    }
]